<template>
  <div>
    <v-btn color="#e21a1a" class="mx-6" v-on:click="getOrderActions" depressed :loading="this.isOrderActionsLoading">
      <v-icon color="white" left>mdi-arrow-right-bottom</v-icon>
      <span class="ReferenceOperatorWorkButtonText">Действия по заявке</span>
    </v-btn>
    <v-dialog v-model="dialog" max-width="1280" persistent>
      <v-card v-if="this.isShowWindow" class="file-upload-card" outlined>
        <v-card-title>{{ order.orderNumber }}</v-card-title>
        <v-card-text>
          <v-expansion-panels mb="3">
            <v-expansion-panel v-for="action in this.orderActions" :key="action.id">
              <v-expansion-panel-header>
                <v-row class="actionHeader">
                  <v-col>
                    <p>Время действия</p>
                    {{ getTime(action.created) }}
                  </v-col>
                  <v-col>
                    <p>Тип действия</p>
                    {{ actionType(action.actionType) }}
                  </v-col>
                  <v-col>
                    <p>Текущий статус</p>
                    {{ nextState(action.nextState) }}
                  </v-col>
                  <v-col>
                    <p>ФИО оператора</p>
                    {{ action.userName }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="render(action.data).length > 0">
                <v-row class="my-0 actionHeader">
                  <v-col class="my-0 pa-0"><p>Информация</p></v-col>
                  <v-col class="my-0 pa-0"><p>Начальная информация</p></v-col>
                  <v-col class="my-0 pa-0"><p>Измененная информация</p></v-col>
                </v-row>
                <v-row class="my-0" v-for="field in render(action.data)" :key="field.id">
                  <v-col class="my-0 pa-0">
                    {{ field.name }}
                  </v-col>
                  <v-col class="my-0 pa-0">
                    {{ showFieldData(field.in, field.type) }}
                  </v-col>
                  <v-col class="my-0 pa-0">
                    {{ showFieldData(field.out, field.type) }}
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="mx-2 text-none my-1" color="grey darken-3" @click="dialog = false">Закрыть</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
// import reportFields from './../helpers/reportFields.json';
import moment from 'moment';
import Helper from '@/helpers/food';
import ReportField from '@/helpers/food/orderActionFields.json';
export default {
  props: {
    order: {
      required: true
    }
  },
  data() {
    return {
      isOrderActionsLoading: false,
      isShowWindow: false,
      dialog: false,
      dataActions: null
    };
  },
  computed: {
    getReportFields() {
      return JSON.parse(JSON.stringify(ReportField));
    },
    ...mapGetters('food', ['orderActions'])
  },
  methods: {
    async getOrderActions() {
      try {
        this.isOrderActionsLoading = true;
        await this.fetchOrderActions(this.order.id);
        this.isShowWindow = true;
        this.dialog = true;
      } catch {
        this.isShowWindow = false;
        this.dialog = false;
      } finally {
        this.isOrderActionsLoading = false;
      }
    },
    getColor(value) {
      return value === 0 ? '#f2ffa6' : '#b6fcca';
    },
    actionType(value) {
      return Helper.getActionTypeByValue(value);
    },
    getTime(value) {
      if (value === undefined || value === null || value.length === 0) return '';
      return moment(value).format('DD/MM/YY, HH:mm:ss');
    },
    nextState(value) {
      return Helper.getOrderStatus(value);
    },
    showField(data, fieldData) {
      if (Object.keys(data).length > 1 && fieldData !== null && fieldData !== undefined) return true;
      return false;
    },
    render(inputData) {
      let data = JSON.parse(JSON.stringify(inputData));
      const result = [];
      let fieldData = this.getReportFields;
      if (data === null) return result;
      let originDataKeys = Object.keys(data);
      if (originDataKeys.length === 0) return result;
      if (originDataKeys.length === 1 && originDataKeys[0] === 'orderId') return result;
      let isProcess = true;
      while (isProcess) {
        isProcess = false;
        originDataKeys = Object.keys(data);
        for (let i = 0; i < originDataKeys.length; i++) {
          if (typeof data[originDataKeys[i]] === 'object') {
            data = { ...data, ...data[originDataKeys[i]] };
            delete data[originDataKeys[i]];
            isProcess = true;
          }
        }
      }
      for (let i = 0; i < originDataKeys.length; i++) {
        if (data[originDataKeys[i]] === undefined || data[originDataKeys[i]] === null || data[originDataKeys[i]] === '') {
          continue;
        }
        let obj = undefined;
        let isIn = true;
        const inn = fieldData.find((field) => field.in === originDataKeys[i]);
        if (inn !== undefined) {
          obj = inn;
          isIn = true;
        } else {
          const out = fieldData.find((field) => field.out === originDataKeys[i]);
          if (out !== undefined) {
            obj = out;
            isIn = false;
          } else {
            obj = { id: 1000 + i, type: 'string', name: originDataKeys[i], exclude: false };
          }
        }
        if (obj.exclude === true) {
          continue;
        }
        const res = result.find((res) => res.id === obj.id);
        if (res === undefined) {
          const currData = { id: obj.id, type: obj.type, name: obj.name };
          if (isIn) {
            currData.in = data[originDataKeys[i]];
          } else {
            currData.out = data[originDataKeys[i]];
          }
          result.push(currData);
        } else {
          if (isIn) {
            res.in = data[originDataKeys[i]];
          } else {
            res.out = data[originDataKeys[i]];
          }
        }
      }
      return result.sort((a, b) => a.id - b.id);
    },

    showFieldData(data, type) {
      if (type !== 'dateFrom' && type !== 'dateTo' && type !== 'cancelDate' && type !== 'returnDate' && type !== 'crtime') return data;
      return this.getTime(data);
    },
    ...mapActions('food', ['fetchOrderActions'])
  },

  mounted() {}
};
</script>
<style scoped>
.ReferenceOperatorWorkButtonText {
  color: white;
}
.actionHeader p {
  font-weight: bold;
}
</style>
