<template>
  <div>
    <NavBar />
    Бистро
    <v-row class="mx-0 mt-2 grey lighten-3 mb-0">
      <v-col md="4" class="d-flex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text @click="downloadReport" disabled v-bind="attrs" v-on="on">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </template>
          <span>Выгрузить в Excel</span>
        </v-tooltip>
        <v-menu
          ref="dateRangePicker"
          v-model="isDateRangePickerVisible"
          :close-on-content-click="false"
          :return-value.sync="dateRange"
          transition="slide-y-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              class="ml-3"
              prepend-inner-icon="mdi-calendar-range"
              hide-details
              readonly
              flat
              solo
              dense
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker v-model="datePickerRange" :first-day-of-week="1" :max="`${currentDate}`" @change="onDatePickerRangeChange" no-title range scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="isDateRangePickerVisible = false">Отмена</v-btn>
            <v-btn text color="primary" @click="onDatePickerRangeSave">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="8" class="pt-0 pt-md-3">
        <v-text-field v-model="search" placeholder="Тип, сообщение" prepend-inner-icon="mdi-magnify" hide-details single-line clearable dense flat solo />
      </v-col>
    </v-row>
    <div class="ReferenceOperatorMainframe">
      <div class="ReferenceUpBoard">
        <div v-if="!this.isOrdersLoading && !this.isOrdersError && this.orders && this.orders.length > 0">
          <div class="ReferenceProcessList">
            <div class="ReferenceProcessListNumber">
              {{ this.orders.length }}
            </div>
            <div class="pt-2">
              {{ this.declOfNum(this.orders.length, ['заказ', 'заказа', 'заказов']) }}
              <v-spacer />
              всего
            </div>
          </div>
          <v-row class="px-2">
            <!-- <v-checkbox class="mr-2" v-model="isShowPaymentTrue" justijy-content-end :label="'Ожидание экспресса'"></v-checkbox> -->
            <!-- <v-checkbox class="mr-2" v-model="created" justijy-content-end :label="'Создан'"></v-checkbox> -->
            <!-- <v-checkbox class="mr-2" v-model="confirmByClient" justijy-content-end :label="'Подтвержден клиентом'"></v-checkbox> -->
            <v-checkbox class="mr-2" v-model="paid" justijy-content-end :label="'Оплачен'"></v-checkbox>
            <!-- <v-checkbox class="mr-2" v-model="sendToRestaurant" justijy-content-end :label="'Готовится'"></v-checkbox> -->
            <!-- <v-checkbox class="mr-2" v-model="confirmByRestaurant" justijy-content-end :label="'Принят рестораном'"></v-checkbox> -->
            <v-checkbox class="mr-2" v-model="cooking" justijy-content-end :label="'Готовится'"></v-checkbox>
            <!-- <v-checkbox class="mr-2" v-model="cooked" justijy-content-end :label="'Приготовлен'"></v-checkbox> -->
            <v-checkbox class="mr-2" v-model="waitingDelivery" justijy-content-end :label="'Готов к выдаче'"></v-checkbox>
            <!-- <v-checkbox class="mr-2" v-model="delivering" justijy-content-end :label="'Доставляется'"></v-checkbox> -->
            <!-- <v-checkbox class="mr-2" v-model="waitingPassClient" justijy-content-end :label="'Ожидает передачи клиенту'"></v-checkbox> -->
            <v-checkbox class="mr-2" v-model="confirm" justijy-content-end :label="'Завершен'"></v-checkbox>
            <v-checkbox class="mr-2" v-model="canceled" justijy-content-end :label="'Отменен'"></v-checkbox>

            <!-- <v-checkbox class="mr-2" v-model="isShowOperatorWorkDone" justijy-content-end :label="'Отправка на почту'"></v-checkbox> -->
            <!-- <v-checkbox class="mr-2" v-model="isShowWorkDone" justijy-content-end :label="'Завершены'"></v-checkbox>
            <v-checkbox class="mr-2" v-model="isCancelReference" justijy-content-end :label="'Отменены'"></v-checkbox> -->
          </v-row>
          <OrderList :orderList="filteredOrders" :queryParams="queryParams" />
        </div>
        <div v-else>
          <v-chip label text-color="white" color="#0066A1">
            {{ this.loadProgress }}
          </v-chip>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import NavBar from '@/components/NavBar';
import OrderList from '@/components/food/OrderList.vue';
import ReportService from '@/services/ReportFoodService';
import Helper from '@/helpers/food';
import moment from 'moment';

export default {
  data() {
    const today = moment().format('YYYY-MM-DD');
    return {
      isDateRangePickerVisible: false,
      datePickerRange: [today, today],
      dateRange: [today, today],
      receiptTypeSelect: [0, 1],
      delayedPayStatusSelect: [0, 1, 2, 3],
      currentDate: today,
      search: '',
      timer: null,
      isShowPaymentTrue: true,
      isShowWaitingOperatorWork: true,
      isShowOperatorWorkDone: true,
      isShowWorkDone: false,
      isCancelReference: false,
      isWorkProgress: true,
      // currentUser: JSON.parse(localStorage.getItem('user')).fio,
      loading: false,
      created: false,
      confirmByClient: false,
      paid: true,
      sendToRestaurant: false,
      confirmByRestaurant: false,
      cooking: true,
      cooked: false,
      waitingDelivery: true,
      delivering: false,
      waitingPassClient: false,
      confirm: false,
      canceled: false
      // isShowCreated: false,
      // isShowPaymentFalse: false,
      // isShowPaymentTrue: true,
      // isShowWaitingOperatorWork: true,
      // isShowOperatorWorkDone: true,
      // isShowWorkDone: false,
      // isCancelReference: false,
    };
  },
  components: {
    NavBar,
    OrderList
  },
  computed: {
    orderFields() {
      return Helper.getOrderListFields();
    },
    filteredOrders() {
      let modifySearch = this.search.toLowerCase();
      let filteredArray = [];
      if (modifySearch !== '') {
        if (this.orders !== undefined && this.orders !== null) {
          filteredArray = this.orders.filter((item) => {
            return (
              item.clientName.toLowerCase().indexOf(modifySearch) !== -1 ||
              item.clientEmail.indexOf(modifySearch) !== -1 ||
              item.clientPhone.indexOf(modifySearch) !== -1 ||
              item.orderNum.indexOf(modifySearch) !== -1
            );
          });
        }
      } else {
        filteredArray = this.orders;
        if (this.created === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 1;
          });
        }
        if (this.confirmByClient === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 2;
          });
        }
        if (this.paid === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 11;
          });
        }
        if (this.sendToRestaurant === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 21;
          });
        }
        if (this.confirmByRestaurant === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 22;
          });
        }
        if (this.cooking === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 23;
          });
        }
        if (this.cooked === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 24;
          });
        }
        if (this.waitingDelivery === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 30;
          });
        }
        if (this.delivering === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 31;
          });
        }
        if (this.waitingPassClient === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 40;
          });
        }
        if (this.confirm === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 50;
          });
        }
        if (this.canceled === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 100;
          });
        }
        // if (this.isWorkProgress === false) {
        //   filtredArray = filtredArray.filter((item) => {
        //     return this.isOrderTaken(item) === false || this.isOrderTaken(item) === this.currentUser;
        //   });
        // }
        // }
      }
      return filteredArray;
    },
    filteredOrders2() {
      let modifySearch = this.search.toLowerCase();
      let filteredArray = [];
      if (modifySearch !== '') {
        filteredArray = this.orders.filter((item) => {
          return (
            item[this.orderFields[0]].toLowerCase().indexOf(modifySearch) !== -1 ||
            item[this.orderFields[1]].indexOf(modifySearch) !== -1 ||
            item[this.orderFields[2]].indexOf(modifySearch) !== -1 ||
            this.getReferenceTypeName(item[this.orderFields[3]]).toLowerCase().indexOf(modifySearch) !== -1 ||
            item[this.orderFields[4]].indexOf(modifySearch) !== -1
          );
        });
      } else {
        filteredArray = this.orders;
        if (this.isShowPaymentTrue === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 3;
          });
        }
        if (this.isShowWaitingOperatorWork === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 4;
          });
        }
        if (this.isShowOperatorWorkDone === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 5;
          });
        }
        if (this.isShowWorkDone === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 6;
          });
        }
        if (this.isCancelReference === false) {
          filteredArray = filteredArray.filter((item) => {
            return item.currentState !== 100;
          });
        }
        if (this.isWorkProgress === false) {
          filteredArray = filteredArray.filter((item) => {
            return this.isOrderTaken(item) === false;
          });
        }
      }
      return filteredArray;
    },
    queryParams() {
      const params = new URLSearchParams();
      params.append('dateStart', this.datePickerRange[0]);
      params.append('dateEnd', this.datePickerRange[1]);
      return params;
    },
    dateRangeText() {
      const dateFrom = moment(this.datePickerRange[0]);
      const dateTo = moment(this.datePickerRange[1]);
      return this.formatDateRangeText(dateFrom, dateTo);
    },
    loadProgress() {
      if (this.isOrdersLoading) {
        return 'Загружаем список';
      } else if (this.isOrdersError) {
        return 'Ошибка загрузки списка: ' + this.isOrdersError;
      } else if (!this.filteredOrders || this.filteredOrders.length <= 0) {
        return 'Список пуст';
      } else {
        return 'Количество записей: ' + this.filteredOrders.length;
      }
    },
    ...mapGetters('food', ['orders']),
    ...mapGetters('food', ['isOrdersLoading']),
    ...mapGetters('food', ['isOrdersError'])
  },
  methods: {
    getTypeName: Helper.getTypeName,
    declOfNum: Helper.declOfNum,
    isOrderTaken(order) {
      if (!order || !order.reservedDate) {
        return false;
      }
      if (moment(order.reservedDate).isBefore(moment().subtract(10, 'm'))) {
        return false;
      }
      if (!order.reservedUser.fio || order.reservedUser.fio.length <= 0) {
        return '';
      }
      return order.reservedUser.fio;
    },
    onDatePickerRangeChange() {
      if (this.datePickerRange[1] < this.datePickerRange[0]) {
        this.datePickerRange.reverse();
      }
    },
    onDatePickerRangeSave() {
      if (this.datePickerRange.length === 1) {
        this.datePickerRange.push(this.currentDate);
      }
      this.$refs.dateRangePicker.save(this.datePickerRange);
      this.updateOrderList();
    },
    formatDateRangeText(dateFrom, dateTo) {
      if (dateTo.diff(dateFrom) === 0) {
        return `${dateFrom.format('D MMM')}`;
      }
      let dateFromFormat, dateToFormat;
      dateFromFormat = dateToFormat = 'D MMM YYYY';
      if (dateFrom.year() === dateTo.year()) {
        if (dateFrom.year() === this.$moment(this.currentDate).year()) {
          dateToFormat = 'D MMM';
        }
        if (dateFrom.month() === dateTo.month()) {
          dateFromFormat = 'D';
        } else {
          dateFromFormat = 'D MMM';
        }
      }
      return `С ${dateFrom.format(dateFromFormat)} по ${dateTo.format(dateToFormat)}`;
    },
    downloadReport() {
      ReportService.downloadOrdersExcel(this.queryParams).then((res) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.setAttribute('download', `Отчет с ${this.datePickerRange[0]} по ${this.datePickerRange[1]} на ${moment().format('YYYY-MM-DD HH-mm-ss')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    },
    updateOrderList() {
      this.fetchOrdersForDates({
        queryParams: this.queryParams
      });
    },
    ...mapActions('food', ['fetchOrdersForDates'])
  },
  mounted() {
    this.updateOrderList();
  }
};
</script>

<style>
.ReferenceProcessList {
  display: flex;
}
.ReferenceProcessListNumber {
  font-size: 50px;
  color: #e21a1a;
  padding: 10px;
  line-height: 1;
  font-weight: normal;
}
</style>
