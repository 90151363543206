<template>
  <div>
    <v-btn color="#e21a1a" depressed v-on:click="getOrder" :loading="this.isOrderLoading">
      <v-icon color="white" left>mdi-arrow-right-bottom</v-icon>
      <span class="ReferenceOperatorWorkButtonText">ПРОСМОТРЕТЬ</span>
    </v-btn>
    <v-dialog v-model="dialog" max-width="1280" persistent>
      <v-card v-if="this.isShowWindow" class="file-upload-card" outlined>
        <v-card-title class="pb-4">{{ this.getOrderName }}</v-card-title>
        <v-container class="OperatorWorkContainer">
          <v-row no-gutters class="RowField" v-for="item in getFieldList(orderById)" :key="item.name">
            <v-col>
              {{ item.name }}
              <v-text-field class="custom-label-color" outlined :label="item.in" :value="item.out" readonly></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="mb-2 d-flex">
          <v-spacer></v-spacer>
          <v-btn type="submit" depressed class="mr-3 text-RussianRail" @click="closeDialog">закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Helper from '@/helpers/food';
import moment from 'moment';
export default {
  props: {
    order: {
      required: true
    },
    queryParams: {
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      isShowWindow: false,
      isOrderLoading: false
    };
  },
  computed: {
    getOrderName() {
      if (this.order.type === 1) {
        return 'Заказ';
      }
      return 'Заказ';
    },
    ...mapGetters('food', ['orderById'])
  },
  methods: {
    async getOrder() {
      try {
        this.isOrderLoading = true;
        await this.fetchOrder(this.order.id);
        this.isShowWindow = true;
        this.dialog = true;
      } catch {
        this.isShowWindow = false;
        this.dialog = false;
      } finally {
        this.isOrderLoading = false;
      }
    },
    getFieldValue(value, type) {
      if (type === 'date') {
        return moment(value).format('DD/MM/YY HH:mm:ss');
      }
      if (value === undefined || value === null) {
        return '';
      }
      return value.toString();
    },
    getFieldList(object) {
      const list = {};
      const keys = Object.keys(object);
      const orderListFields = Helper.getOrderFields();
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const id = keys[i].toLowerCase().replace('modify', '');
        if (object[key] === undefined || object[key] === null || object[key].length <= 0) {
          continue;
        }
        let findField = orderListFields.find((element) => {
          if (element.in === key || element.out === key) {
            return element;
          }
        });
        if (!findField) {
          findField = { name: key, in: key, out: null, exclude: false };
        }
        if (findField.exclude === true) {
          continue;
        }
        if (list[id] === undefined) {
          list[id] = { name: '', in: '', out: '' };
        }
        list[id].name = findField.name;
        if (findField.in === key) {
          list[id].in = this.getFieldValue(object[key], findField.type);
        } else {
          list[id].out = this.getFieldValue(object[key], findField.type);
        }
      }
      return list;
    },
    changeDateForm(pickerDate) {
      if (pickerDate === '' || pickerDate === undefined) return '';
      return pickerDate.split('-').reverse().join('.');
    },
    closeDialog() {
      this.dialog = false;
    },
    updateReferenceList() {
      this.fetchOrdersForDates({
        queryParams: this.queryParams
      });
    },
    ...mapActions('food', ['fetchOrdersForDates']),
    ...mapActions('food', ['fetchOrder'])
  }
};
</script>
<style>
.RowField {
  padding-left: 15px;
}
.custom-label-color .v-label .theme--light.v-label {
  color: rgb(22, 216, 241);
  opacity: 1;
}
.OperatorWorkContainer > .RowField {
  /* flex: 1 1 33%; grow | shrink | basis */
  /* height: 100px; */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 30%;
}
.OperatorWorkContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.ReferenceOperatorWorkButtonText {
  color: white;
}
v-col {
  padding: 0;
}
</style>
