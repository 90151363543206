<template>
  <v-expansion-panels mb="3">
    <v-expansion-panel v-for="order in this.orderList" :key="order.id">
      <v-expansion-panel-header :color="getColor(order)">
        <v-row>
          <v-col>
            <p class="ListTableHeader">Заказ №</p>
            {{ getOrderField(order, 3) }}
          </v-col>
          <v-col>
            <p class="ListTableHeader">Заказано</p>
            {{ formatDate(getOrderField(order, 4)) }}
          </v-col>
          <v-col>
            <p class="ListTableHeader">Статус</p>
            {{ getStatus(order) }}
            <div v-if="isOrderTaken(order) !== false">
              <p style="color: #e21a1a" class="pt-3">Забронирована оператором: {{ isOrderTaken(order) }}</p>
            </div>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content :color="getColor(order)">
        <v-row>
          <v-col>
            <p class="ListTableHeader">ФИО</p>
            {{ getOrderField(order, 0) }}
          </v-col>
          <v-col>
            <p class="ListTableHeader">Почта</p>
            {{ getOrderField(order, 1) }}
          </v-col>
          <v-col>
            <p class="ListTableHeader">Телефон</p>
            {{ getOrderField(order, 2) }}
          </v-col>
        </v-row>
        <v-card-actions>
          <OrderItem :order="order" :queryParams="queryParams" />
          <ActionItem :order="order" :queryParams="queryParams"
        /></v-card-actions>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapGetters } from 'vuex';
import Helper from '@/helpers/food';
import ViewHelper from '@/helpers/view.helper';
import OrderItem from '@/components/food/OrderItem';
import ActionItem from '@/components/food/OrderAction.vue';

import moment from 'moment';
export default {
  props: {
    orderList: {
      type: Array,
      required: true
    },
    queryParams: {
      required: true
    }
  },
  components: {
    OrderItem,
    ActionItem
  },
  computed: {
    ...mapGetters('food', ['orders'])
  },
  methods: {
    getOrderField(order, field) {
      return order[Helper.getOrderListFields()[field]];
    },
    getSeatName: Helper.getSeatName,
    getCarName: Helper.getCarName,
    getTariffName: Helper.getTariffName,
    getOrderStatus: Helper.getOrderStatus,
    formatDate: ViewHelper.formatDate,
    formatDateOnly: ViewHelper.formatDateOnly,
    getTypeName: Helper.getTypeName,
    getStatus(order) {
      return Helper.getOrderStatus(order.currentState);
    },
    getColor(item) {
      let color = '';
      if (item.currentState === 6) color = '#00FF7F20';
      else if (this.isOrderTaken(item) === false && item.currentState === 4) color = '#FFFF0020';
      else if (this.isOrderTaken(item).length > 0 && item.currentState === 4) color = '#1E90FF20';
      else if (item.currentState === 100) color = '#F1525220';
      return color;
    },
    isOrderTaken(order) {
      if (!order || !order.reservedDate) {
        return false;
      }
      if (moment(order.reservedDate).isBefore(moment().subtract(10, 'm'))) {
        return false;
      }
      if (!order.reservedUser.fio || order.reservedUser.fio.length <= 0) {
        return '';
      }
      return order.reservedUser.fio;
    }
  }
};
</script>
<style>
p.ListTableHeader {
  font-weight: bold;
}
</style>
